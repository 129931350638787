import Home from '@/views/Home.vue'
import Dataset from '@/views/Dataset.vue'
import VueRouter from 'vue-router'

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/dataset/:organization/:datasetGuid',
            name: 'dataset',
            component: Dataset,
        },
    ]
})