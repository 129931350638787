<template>
  <div id="app">
    <b-button v-if="!userData" @click="handleLoginClick">Log in</b-button>
    <b-button v-if="userData" @click="handleLogoutClick">Log out</b-button>
    <b-container><router-view v-if="userData"></router-view> </b-container>
  </div>
</template>

<script>
import OgslAuthClient from "ogsl-auth-client";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      userData: null,
    };
  },
  mounted() {
    const this_ = this;

    axios.interceptors.request.use(function (config) {
      if (config.url.startsWith(process.env.VUE_APP_API_BASE_URL)) {
        config.headers["X-AUTH-TOKEN"] = this_.$cookies.get("sessionId");
      }
      return config;
    });

    OgslAuthClient.eventEmitter.addListener(
      OgslAuthClient.EVENT_USER_DATA_CHANGED,
      (data) => {
        if (!data) {
          this_.userData = null;
          return;
        }

        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/can_use_api`)
          .then(() => {
            this_.userData = data;
          })
          .catch(() => {
            this.$bvToast.toast(
              "Ce service est réservé aux employés de l'ogsl",
              {
                title: "Error",
                variant: "danger",
                autoHideDelay: 10000,
                solid: true,
              }
            );
          });
      }
    );
    OgslAuthClient.connect();
  },
  methods: {
    handleLoginClick() {
      OgslAuthClient.openLoginWindow();
    },
    handleLogoutClick() {
      OgslAuthClient.logout();
    },
  },
};
</script>

<style>
</style>
