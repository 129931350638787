<template>
  <div>
    <section style="background-color: #c6eada; padding: 10px">
      <h2>PREPROD</h2>
      <b-form-group label="Publication  from metadata form to preprod:">
        <b-button
          :disabled="blockingActionInProgess"
          @click="publishToPreprod"
          variant="primary"
          >Publish</b-button
        >
      </b-form-group>
      <b-form-group label="Changelog :">
        <b-form-textarea
          :disabled="changelogBroken || blockingActionInProgess"
          v-model="changelog"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-button
          :disabled="changelogBroken || blockingActionInProgess"
          @click="updateChangelog"
          variant="primary"
          >Update changelog</b-button
        >
      </b-form-group>

      <b-form-group label="Links:"> </b-form-group>
      <ul>
        <li>
          Dataset Catalogue url:
          <CheckedLink
            :url="preprod.links.catalogueDatasetUrl"
            :brokenLinks="preprod.brokenLinks"
          />
        </li>
        <li>
          Dataset xml url:
          <CheckedLink
            :url="preprod.links.wafDatasetUrl"
            :brokenLinks="preprod.brokenLinks"
          />
        </li>
        <li>
          Changelog url:
          <CheckedLink
            :url="preprod.links.changelogUrl"
            :brokenLinks="preprod.brokenLinks"
          />
        </li>
        <li>
          Organization Harvester logs:
          <a target="_blank" :href="preprod.links.harvesterUrl">{{
            preprod.links.harvesterUrl
          }}</a>
        </li>
      </ul>
    </section>

    <section
      style="background-color: rgb(255 194 176); padding: 10px"
      class="mt-5"
    >
      <h2>PRODUCTION</h2>
      <b-form-group label="Publish preprod to production:"> </b-form-group>
      <b-button
        :disabled="!isProductionPublicationReady"
        @click="publishMetadataToProduction"
        variant="warning"
        >Publish metadata</b-button
      >
      <b-button
        class="ml-2"
        :disabled="!isProductionPublicationReady"
        @click="publishResourcesToProduction"
        variant="warning"
        >Publish resources</b-button
      >
      <b-button
        class="ml-2"
        @click="publishOrganizationToProduction"
        variant="warning"
        >Publish organization</b-button
      >
      <b-form-group label="Links:"> </b-form-group>
      <ul>
        <li>
          Dataset Catalogue url:
          <CheckedLink
            :url="production.links.catalogueDatasetUrl"
            :brokenLinks="production.brokenLinks"
          />
        </li>
        <li>
          Dataset xml url:
          <CheckedLink
            :url="production.links.wafDatasetUrl"
            :brokenLinks="production.brokenLinks"
          />
        </li>
        <li>
          Changelog url:
          <CheckedLink
            :url="production.links.changelogUrl"
            :brokenLinks="production.brokenLinks"
          />
        </li>
        <li>
          Organization Harvester logs:
          <a target="_blank" :href="production.links.harvesterUrl">{{
            production.links.harvesterUrl
          }}</a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CheckedLink from "@/components/CheckedLink.vue";

export default {
  components: { CheckedLink },
  data() {
    return {
      blockingActionInProgess: false,
      changelog: "",
      changelogBroken: true,
      preprod: this.createEnvironmentDict("preprod"),
      production: this.createEnvironmentDict("production"),
    };
  },
  mounted() {
    checkLinks(this.preprod);
    checkLinks(this.production);

    axios
      .get(
        `${process.env.VUE_APP_API_BASE_URL}/dataset_changelog/${this.$route.params.organization}/${this.$route.params.datasetGuid}`
      )
      .then((response) => {
        this.changelog = response.data;
        this.changelogBroken = false;
      })
      .catch(() => {
        this.$bvToast.toast("Could not get changelog", {
          title: "Error",
          variant: "danger",
          autoHideDelay: 10000,
          solid: true,
        });
      });
  },
  computed: {
    isProductionPublicationReady() {
      return (
        !this.blockingActionInProgess &&
        !this.preprod.brokenLinks.includes(
          this.preprod.links.wafDatasetUrl,
          this.preprod.links.changelogUrl
        )
      );
    },
  },
  methods: {
    createEnvironmentDict(environment) {
      const catalogue_base_url =
        environment === "production"
          ? process.env.VUE_APP_CATALOGUE_PRODUCTION_BASE_URL
          : process.env.VUE_APP_CATALOGUE_PREPROD_BASE_URL;

      const waf_base_url =
        environment === "production"
          ? process.env.VUE_APP_WAF_PRODUCTION_BASE_URL
          : process.env.VUE_APP_WAF_PREPROD_BASE_URL;

      return {
        links: {
          catalogueDatasetUrl:
            catalogue_base_url +
            "/dataset/ca-cioos_" +
            this.$route.params.datasetGuid,
          wafDatasetUrl:
            waf_base_url +
            "/metadata-files/" +
            this.$route.params.organization +
            "/" +
            this.$route.params.datasetGuid +
            ".xml",
          changelogUrl:
            waf_base_url +
            "/metadata-files/" +
            this.$route.params.organization +
            "/" +
            this.$route.params.datasetGuid +
            "_changelog.txt",
          harvesterUrl:
            catalogue_base_url +
            "/harvest/admin/" +
            this.$route.params.organization +
            "_metadata_files_autogenerated",
          catalogueOrganizationCreationUrl:
            catalogue_base_url + "/organization/new",
        },
        brokenLinks: [],
      };
    },
    updateChangelog() {
      this.blockingActionInProgess = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/dataset_changelog/${this.$route.params.organization}/${this.$route.params.datasetGuid}`,
          { changelog: this.changelog }
        )
        .then(() => {
          this.$bvToast.toast("Changelog updated sucessfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .catch((error) => {
          let errorMessage = error.response
            ? error.response.data.message
            : error.message;

          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .then(() => {
          this.blockingActionInProgess = false;
        });
    },
    publishToPreprod() {
      this.blockingActionInProgess = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/publish_dataset_to_preprod/${this.$route.params.organization}/${this.$route.params.datasetGuid}`
        )
        .then((response) => {
          let variant = "success";

          if (response.data["errored"] > 0) variant = "danger";

          this.$bvToast.toast(
            "Harvest result: " + JSON.stringify(response.data),
            {
              title: "Publication process to preprod is finished",
              variant: variant,
              autoHideDelay: 10000,
              solid: true,
            }
          );
          checkLinks(this.preprod);
        })
        .catch((error) => {
          let errorMessage = error.response
            ? error.response.data.message
            : error.message;

          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .then(() => {
          this.blockingActionInProgess = false;
        });
    },
    publishMetadataToProduction() {
      this.blockingActionInProgess = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/publish_dataset_to_production/${this.$route.params.organization}/${this.$route.params.datasetGuid}`
        )
        .then((response) => {
          let variant = "success";

          if (response.data["errored"] > 0) variant = "danger";

          this.$bvToast.toast(
            "Harvest result: " + JSON.stringify(response.data),
            {
              title: "Publication process to production is finished",
              variant: variant,
              autoHideDelay: 10000,
              solid: true,
            }
          );
          checkLinks(this.production);
        })
        .catch((error) => {
          let errorMessage = error.response
            ? error.response.data.message
            : error.message;

          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .then(() => {
          this.blockingActionInProgess = false;
        });
    },
    publishResourcesToProduction() {
      this.blockingActionInProgess = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/publish_resources_to_production/${this.$route.params.organization}/${this.$route.params.datasetGuid}`
        )
        .then((response) => {
          this.$bvToast.toast(response.data, {
            title: "Successfuly published to production",
            variant: "success",
            autoHideDelay: 10000,
            solid: true,
          });
          checkLinks(this.production);
        })
        .catch((error) => {
          let errorMessage = error.response
            ? error.response.data.message
            : error.message;

          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .then(() => {
          this.blockingActionInProgess = false;
        });
    },
    publishOrganizationToProduction() {
      this.blockingActionInProgess = true;
      axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/publish_organization_to_production/${this.$route.params.organization}`
        )
        .then((response) => {
          this.$bvToast.toast(response.data, {
            title: "Organization successfully published to production",
            variant: "success",
            autoHideDelay: 10000,
            solid: true,
          });
          checkLinks(this.production);
        })
        .catch((error) => {
          let errorMessage = error.response
            ? error.response.data.message
            : error.message;

          this.$bvToast.toast(errorMessage, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 10000,
            solid: true,
          });
        })
        .then(() => {
          this.blockingActionInProgess = false;
        });
    },
  },
};

async function checkLinks(environmentDict) {
  for (const key of ["catalogueDatasetUrl", "wafDatasetUrl", "changelogUrl"]) {
    const brokenLinks = environmentDict.brokenLinks;
    const link = environmentDict.links[key];
    const wasBroken = brokenLinks.indexOf(link) > -1;
    const isActive = await checkLink(link);

    if (isActive && wasBroken) {
      brokenLinks.splice(brokenLinks.indexOf(link), 1);
    } else if (!isActive && !wasBroken) {
      brokenLinks.push(link);
    }
  }
}

async function checkLink(url) {
  try {
    const response = await axios.head(url);
    return response.status === 200;
  } catch (err) {
    return false;
  }
}
</script>

<style scoped></style>
