import Vue from 'vue'
import App from './App.vue'

import BootstrapVue from "bootstrap-vue"
import router from '@/router'

import VueSelect from 'vue-select'

import { setEnvironment } from 'ogsl-auth-client'

setEnvironment("production")

Vue.use(BootstrapVue)
Vue.use(router)

Vue.component('v-select', VueSelect)

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
