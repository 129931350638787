<template>
  <div>
    <b-form-group label="Organization :">
      <v-select
        :options="organizations"
        v-model="selectedOrganization"
      ></v-select>
      if the organization you need does not exists, create one in the catalogue:
      <a target="_blank" :href="catalogueNewOrganizationUrl">{{
        catalogueNewOrganizationUrl
      }}</a>
    </b-form-group>
    <b-form-group label="Dataset GUID :">
      <v-select :options="datasetsIds" v-model="selectedDataset"></v-select>
    </b-form-group>

    <b-button
      :disabled="canContinue === false"
      @click="goToDatasetPage"
      variant="primary"
      >Continue</b-button
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      organizations: [],
      datasets: [],
      selectedDataset: null,
      selectedOrganization: null,
      catalogueNewOrganizationUrl:
        process.env.VUE_APP_CATALOGUE_PREPROD_BASE_URL + "/organization/new",
    };
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_CATALOGUE_PREPROD_BASE_URL}/api/action/organization_list`
      )
      .then((response) => {
        this.organizations = response.data.result.sort();
      });

    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/publishable_datasets`)
      .then((response) => {
        this.datasets = response.data;
      });
  },
  computed: {
    datasetsIds() {
      return this.datasets.map((item) => item.identifier);
    },
    canContinue() {
      return Boolean(this.selectedDataset && this.selectedOrganization);
    },
  },
  methods: {
    goToDatasetPage() {
      this.$router.push({
        name: "dataset",
        params: {
          organization: this.selectedOrganization,
          datasetGuid: this.selectedDataset,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
