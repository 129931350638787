<template>
  <a
    v-bind:class="{
      broken: brokenLinks.includes(url),
    }"
    target="_blank"
    :href="url"
    >{{ url }}</a
  >
</template>

<script>
export default {
  props: ["url", "brokenLinks"],
};
</script>

<style scoped>
.broken {
  text-decoration: line-through;
}
</style>